class AppConfig {

    getServerUrl() {
        // STOP EDITING THIS FILE..
        if(window.location.hostname == 'localhost'){
            return "https://Khales.paymobsolutions.com";
        }else{
            if(window.location.port === ""){
                return "https://" + window.location.hostname;
            }else{
                return "https://" + window.location.hostname + ":" + window.location.port;
            }
        }
    }
}

export let appConfig = new AppConfig();
