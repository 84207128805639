import {httpClient} from './HttpClient';
import {appConfig} from "./AppConfig";
import cookie from 'react-cookies';
let cookies = cookie.loadAll();

class Auth {
    
logout() {
    let token = this.getMerchantToken();
    //send logout request
      let config = {
          headers: {
              "Cache-Control": "no-cache",
              "Content-Type": "application/json",
          }
      };
      httpClient.getKH(
          "/api/logout?token=" + token,
          config,
          (resp) => {
            //remove cookie data
            cookie.remove('token')
            cookie.remove('id')
            cookie.remove('name')
            cookie.remove('type')
            cookie.remove('permsDict')
            cookie.remove('permNames')
            cookie.remove('noBank')
            window.location.reload();
          },
          (error) => {
            //remove cookie data
            cookie.remove('token')
            cookie.remove('id')
            cookie.remove('name')
            cookie.remove('type')
            cookie.remove('permsDict')
            cookie.remove('permNames')
            cookie.remove('noBank')
            window.location.reload();
          }
      );
}

isLoggedIn() {
    return !!cookies.token ;
}

getMerchantName(){
    if (cookies.name) {
        return cookies.name;
    }
    return null;
}
getMerchantId(){
    if (cookies.id) {
        return cookies.id;
    }
    return null;
}
getMerchantType(){
    if (cookies.type) {
        return cookies.type;
    }
    return null;
}
getMerchantToken() {
    if (cookies.token) {
        return cookies.token;
    }
    return null;
}
getMerchantPermNames(){
    if (cookies.permNames) {
        return cookies.permNames;
    }
    return null;
}
}

export let auth = new Auth();
