import React from 'react';
import Loader from './../../assets/img/loader.svg';
import './waiting.css';

const waiting = (props) => {
    return (
        <div className="waitingComponent" style={{height: props.height}}>
            <div className="waitingContent">
                <img src={Loader} alt="Loader" />
            </div>
        </div>        
    );
}

export default waiting;