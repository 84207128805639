import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import {auth} from './tools/Auth';
import './App.scss';
import Waiting from './views/Waiting/waiting'

const loading = () => <div className="animated fadeIn pt-3 text-center full_loading"><Waiting height="100vh"/></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const ForgetPassword = Loadable({
  loader: () => import('./views/Pages/ForgetPassword/ForgetPassword'),
  loading
});




// const Page404 = Loadable({
//   loader: () => import('./views/Pages/Page404'),
//   loading
// });

// const Page500 = Loadable({
//   loader: () => import('./views/Pages/Page500'),
//   loading
// });


class App extends Component {
  setIndexDir() {
    var html = document.getElementsByTagName("html")[0];
    var att = document.createAttribute("dir");
    if(localStorage.lang == "ar"){
      att.value = "rtl";
    }else{
      att.value = "ltr";
    }
    html.setAttributeNode(att);
  }
  render() {
    this.setIndexDir();
    if(!localStorage.lang){
      localStorage.lang= "en";
    }

    if(localStorage.lang != "en" && localStorage.lang!="ar"){
      localStorage.lang= "en";
      window.location.reload();
    }
    return (
      <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={!auth.isLoggedIn() ? Login :  DefaultLayout } />
            {/* <Route exact path="/login" name="Login Page" component={!auth.isLoggedIn() ? DefaultLayout :  DefaultLayout } /> */}

            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/forgetpassword" name="ForgetPassword Page" component={ForgetPassword} />
            <Route path="/" name="Home" options={{ title: "Home" }} component={auth.isLoggedIn() ? DefaultLayout :  Login } />
            {/* <Route path="/" name="Home"  component={auth.isLoggedIn() ? DefaultLayout :  DefaultLayout } /> */}

          </Switch>
      </HashRouter>
    );
  }
}

export default App;
